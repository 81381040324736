import { ArrowLeftOutlined, PlusOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons'
import {
  Button,
  Drawer,
  Empty,
  message,
  Modal,
  Spin,
  InputNumber as AntInputNumber,
  Tooltip,
} from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Checkbox, Input, InputNumber, Radio, Select } from 'formik-antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Link, useParams } from 'react-router-dom'

import { API_URL } from '../../../../constants'
import { NotFound } from '../../../general/NotFound'
import FormItem from '../../../utility/FormItem'
import { Wrapper } from '../user/UserPage'

const { Option } = Select

const Brand = () => {
  // #region Constants
  const queryClient = new useQueryClient()
  const { id } = useParams()
  const [brand, setBrand] = useState(null)
  const [subscriptions, setSubscriptions] = useState([])
  const [invoices, setInvoices] = useState([])
  const [admins, setAdmins] = useState(false)
  const [showTransactions, setShowTransactions] = useState(false)
  const [addFunds, setAddFunds] = useState(0)
  const [addCredits, setAddCredits] = useState(0)
  const [addContacts, setAddContacts] = useState(0)
  const [addFundsLoading, setAddFundsLoading] = useState(false)
  const [addReportCreditsLoading, setAddReportCreditsLoading] = useState(false)
  const [addSearchContactsLoading, setAddSearchContactsLoading] = useState(false)
  const [showInvoices, setShowInvoices] = useState(false)
  const [showShopifyDetails, setShowShopifyDetails] = useState(false)
  const [showSubscriptions, setShowSubscriptions] = useState(false)
  const [showSearchContacts, setShowSearchContacts] = useState(false)
  const [showReportCredits, setShowReportCredits] = useState(false)
  const [removeUserVisible, setRemoveUserVisible] = useState(false)
  const [removeUserLoading, setRemoveUserLoading] = useState(false)
  const [expandedSubscriptions, setExpandedSubscriptions] = useState([])
  // #endregion Constants

  // #region Queries
  const { data, status } = useQuery(['brand', parseInt(id)], async () => {
    try {
      const { data } = await axios.get(`${API_URL}/admin/brands/${id}`)
      return data
    } catch (err) {
      if (err.response.status === 404) return null
    }
  })
  // #endregion Queries

  // #region Effects
  useEffect(() => {
    if (data && status === 'success') {
      setBrand(data.brand)
      setSubscriptions(data.subscriptions)
      setInvoices(data.invoices)
    }
  }, [data, status])

  useEffect(() => {
    axios
      .get(`${API_URL}/admins`)
      .then(res => {
        setAdmins(res.data.administrators)
      })
      .catch(() => {
        setAdmins([])
      })
  }, [id])
  // #endregion Effects

  // #region Functions
  const handleAddFunds = () => {
    setAddFundsLoading(true)
    axios
      .post(`${API_URL}/brand/${id}/wallet-balance`, {
        amount: addFunds,
      })
      .then(() => {
        message.success('Funds Added')
        queryClient.invalidateQueries(['brand', parseInt(id)])
      })
      .catch(() => {
        message.error('Error Adding Funds')
      })
      .finally(() => setAddFundsLoading(false))
  }

  const handleAddSearchContacts = () => {
    setAddSearchContactsLoading(true)
    axios
      .post(`${API_URL}/brand/${id}/search-contact-credits`, {
        amount: addContacts,
      })
      .then(() => {
        message.success('Search Contacts Added')
        queryClient.invalidateQueries(['brand', parseInt(id)])
      })
      .catch(() => {
        message.error('Error Adding Search Contacts')
      })
      .finally(() => setAddSearchContactsLoading(false))
  }

  const handleAddReportsCredits = () => {
    setAddReportCreditsLoading(true)
    axios
      .post(`${API_URL}/brand/${id}/deep-insight-credits`, {
        amount: addCredits,
      })
      .then(() => {
        message.success('Reports Credits Added')
        queryClient.invalidateQueries(['brand', parseInt(id)])
      })
      .catch(() => {
        message.error('Error Adding Reports Credits')
      })
      .finally(() => setAddReportCreditsLoading(false))
  }

  const updateBrand = async values => {
    try {
      const res = await axios.put(`${API_URL}/brand/${brand.id}`, values)
      message.success(res.data.message || 'Brand Updated')
      queryClient.invalidateQueries(['brand', parseInt(id)])
    } catch (err) {
      message.error(`${err.response.data?.err}${err.response.data?.errCode}`)
    }
  }

  const removeBrandUser = async () => {
    setRemoveUserLoading(true)
    const userId = removeUserVisible
    try {
      await axios.delete(`${API_URL}/brand/${brand.id}/user/${userId}`)
      setRemoveUserLoading(false)
      setRemoveUserVisible(false)
      message.success('User removed from brand')
      queryClient.invalidateQueries(['brand', parseInt(id)])
    } catch (err) {
      message.error(err.response.data?.err || 'Sorry, something went wrong.')
    }
  }

  // #endregion Functions

  return (
    <Wrapper>
      {status === 'success' &&
        (brand ? (
          <Formik
            initialValues={{
              name: brand.name,
              specialistId: brand.specialistId,
              monthlyContacts: brand.monthlyContacts,
              monthlyReports: brand.monthlyReports,
              chargebeeId: brand.extraData.chargebeeId,
              affiliateBeta: brand.extraData.affiliateBeta,
              awinId: brand.extraData.awinId,
              shareasaleId: brand.extraData.shareasaleId,
              shareasaleBannerId: brand.extraData.shareasaleBannerId,
              rakutenId: brand.extraData.rakutenId,
              impactRadiusId: brand.extraData.impactRadiusId,
              cjId: brand.extraData.cjId,
              exemptFromPayment: brand.extraData.exemptFromPayment,
              website: brand.website,
              awinFeeType: brand.extraData.awinFeeType || 'commission',
              awinFeePercent: 5,
              affiliateStoreName: brand.extraData.affiliateStoreName || '',
              limitedPreview: brand.extraData?.limitedPreview || false,
              blockAccess: brand.extraData?.blockAccess || false,
            }}
            onSubmit={updateBrand}>
            {({ values, isSubmitting }) => (
              <Form className='page-inner'>
                <div className='header'>
                  <Link to='/brands'>
                    <ArrowLeftOutlined /> All Brands
                  </Link>

                  <h1>{brand.name}</h1>

                  <Button
                    loading={isSubmitting}
                    htmlType='submit'
                    type='primary'
                    icon={<SaveOutlined />}>
                    Save
                  </Button>
                </div>

                <div className='sections'>
                  <section>
                    <div className='section-header'>
                      <h2>Basic Settings</h2>
                    </div>
                    <FormItem name='name' label='Name'>
                      <Input name='name' />
                    </FormItem>
                    <FormItem name='website' label='Website'>
                      <Input name='website' />
                    </FormItem>
                    <FormItem name='specialistId' label='Specialist'>
                      <Select
                        placeholder='Select'
                        name='specialistId'
                        showSearch
                        allowClear
                        style={{ width: '100%' }}
                        filterOption={(inputValue, option) =>
                          option.label?.toLowerCase().includes(inputValue.toLowerCase())
                        }>
                        {!!admins?.length &&
                          admins.map(admin => (
                            <Option
                              key={admin.id}
                              value={admin.id}
                              label={`${admin.firstName} ${admin.lastName}`}>
                              {`${admin.firstName} ${admin.lastName}`}
                            </Option>
                          ))}
                      </Select>
                    </FormItem>
                  </section>

                  <section>
                    <div className='section-header'>
                      <h2>Automated Payments</h2>
                    </div>
                    <p>
                      Current Balance: ${brand?.paymentTransactions[0]?.balance?.toFixed(2) || 0}
                    </p>
                    <div className='input-btn-wrapper'>
                      <AntInputNumber value={addFunds} onChange={e => setAddFunds(e)} />
                      <Button
                        icon={<PlusOutlined />}
                        loading={addFundsLoading}
                        onClick={() => handleAddFunds()}
                        type='primary'>
                        Add
                      </Button>
                      <Button
                        disabled={!brand.paymentTransactions?.length}
                        onClick={() => setShowTransactions(true)}
                        type='secondary'>
                        View History
                      </Button>
                    </div>
                    <Drawer
                      title='Transaction History'
                      open={showTransactions}
                      onClose={() => setShowTransactions(false)}>
                      <pre>{JSON.stringify(brand.paymentTransactions, null, 2)}</pre>
                    </Drawer>
                  </section>

                  <section>
                    <div className='section-header'>
                      <h2>Credits</h2>
                    </div>

                    <div className='section-inner-wrapper'>
                      <h3>Deep Insights</h3>
                      <p>Current Balance: {brand?.reportCredits[0]?.currentQty || 0}</p>
                      <div className='input-btn-wrapper'>
                        <AntInputNumber value={addCredits} onChange={e => setAddCredits(e)} />
                        <Button
                          loading={addReportCreditsLoading}
                          icon={<PlusOutlined />}
                          onClick={() => handleAddReportsCredits()}
                          type='primary'>
                          Add
                        </Button>
                        <Button
                          disabled={!brand?.reportCredits?.length}
                          onClick={() => setShowReportCredits(true)}
                          type='secondary'>
                          View History
                        </Button>
                      </div>
                      <Modal
                        footer={null}
                        open={showReportCredits}
                        onCancel={() => setShowReportCredits(false)}>
                        <pre>{JSON.stringify(brand.reportCredits, null, 2)}</pre>
                      </Modal>
                      <FormItem label='Monthly Credits'>
                        <InputNumber name='monthlyReports' />
                      </FormItem>
                    </div>

                    <div className='section-inner-wrapper'>
                      <h3>Search Contacts</h3>
                      <p>Current Balance: {brand?.searchContacts[0]?.currentQty || 0}</p>
                      <div className='input-btn-wrapper'>
                        <AntInputNumber value={addContacts} onChange={e => setAddContacts(e)} />
                        <Button
                          loading={addSearchContactsLoading}
                          icon={<PlusOutlined />}
                          onClick={() => handleAddSearchContacts()}
                          type='primary'>
                          Add
                        </Button>
                        <Button
                          disabled={!brand?.searchContacts?.length}
                          onClick={() => setShowSearchContacts(true)}
                          type='secondary'>
                          View History
                        </Button>
                        <Modal
                          footer={null}
                          open={showSearchContacts}
                          onCancel={() => setShowSearchContacts(false)}>
                          <pre>{JSON.stringify(brand.searchContacts, null, 2)}</pre>
                        </Modal>
                      </div>
                      <FormItem label='Monthly Credits'>
                        <InputNumber name='monthlyContacts' />
                      </FormItem>
                    </div>
                  </section>

                  <section>
                    <div className='section-header'>
                      <h2>Chargebee Settings</h2>
                    </div>
                    <FormItem name='chargebeeId' label='Chargebee ID'>
                      <Input name='chargebeeId' />
                    </FormItem>
                    <Checkbox name='exemptFromPayment'>Exempt from Overdue Payments</Checkbox>
                  </section>

                  <section>
                    <div className='section-header'>
                      <h2>Affiliate</h2>
                    </div>
                    <FormItem name='awinId' label='Awin ID'>
                      <Input name='awinId' />
                    </FormItem>
                    {values?.awinId && (
                      <>
                        <FormItem name='awinFeeType' label='Awin Fee type'>
                          <Radio
                            checked={values.awinFeeType === 'commission'}
                            name='awinFeeType'
                            value='commission'>
                            % of Commission
                          </Radio>
                          <Radio
                            checked={values.awinFeeType === 'sale'}
                            name='awinFeeType'
                            value='sale'>
                            % of Sale
                          </Radio>
                        </FormItem>
                        <FormItem name='awinFeePercent' label='Awin Fee %'>
                          <InputNumber name='awinFeePercent' />
                        </FormItem>
                      </>
                    )}
                    <FormItem name='shareasaleId' label='ShareASale ID'>
                      <Input name='shareasaleId' />
                    </FormItem>
                    <FormItem name='shareasaleBannerId' label='ShareASale Banner ID'>
                      <Input name='shareasaleBannerId' />
                    </FormItem>
                    <FormItem name='rakutenId' label='Rakuten ID'>
                      <Input name='rakutenId' />
                    </FormItem>
                    <FormItem name='impactRadiusId' label='Impact Radius Program (Campaign) ID'>
                      <Input name='impactRadiusId' />
                    </FormItem>
                    <FormItem name='cjId' label='CJ Affiliate (Advertiser) ID'>
                      <Input name='cjId' />
                    </FormItem>
                    <FormItem
                      label='Affiliate Store URL'
                      subtext={`Input the URL of the brand's online affiliate store`}>
                      <Input name='affiliateStoreName' placeholder='eg. https://creator.co' />
                    </FormItem>
                  </section>

                  {!!brand?.shopifyStores?.length && (
                    <section>
                      <div className='section-header'>
                        <h2>Shopify</h2>
                      </div>
                      <div>
                        {brand?.shopifyWebhooks?.length ? (
                          <Button onClick={() => setShowShopifyDetails(true)} type='link'>
                            View Details
                          </Button>
                        ) : (
                          <Empty description='No webhooks.' />
                        )}
                      </div>
                    </section>
                  )}

                  <Drawer
                    title='Shopify'
                    open={showShopifyDetails}
                    onClose={() => setShowShopifyDetails(false)}>
                    <h3>Webhooks</h3>
                    {brand.shopifyWebhooks?.map(webhook => (
                      <pre key={webhook.id}>{JSON.stringify(webhook, null, 2)}</pre>
                    ))}
                  </Drawer>

                  <section>
                    <div className='section-header'>
                      <h2>Subscriptions</h2>
                      {!subscriptions?.length && (
                        <Checkbox name='blockAccess' checked={values.blockAccess}>
                          <Tooltip title='Prevent free access to the Creator Search and Campaign Builder'>
                            Disable Free Access
                          </Tooltip>
                        </Checkbox>
                      )}
                    </div>
                    {subscriptions?.length ? (
                      <Button onClick={() => setShowSubscriptions(true)} type='link'>
                        View Details
                      </Button>
                    ) : (
                      <Empty description='No subscriptions.' />
                    )}

                    <Drawer
                      title='Subscriptions'
                      open={showSubscriptions}
                      onClose={() => setShowSubscriptions(false)}>
                      {subscriptions.map((subscription, i) => (
                        <div
                          key={i}
                          className='subscription'
                          style={{
                            borderBottom: '1px solid lightgray',
                            marginBottom: '16px',
                            paddingBottom: '12px',
                          }}>
                          <h3>{subscription.subscription_items[0]?.item_price_id}</h3>
                          <p style={{ color: subscription.status === 'active' ? 'green' : 'red' }}>
                            Status: {subscription.status}
                          </p>
                          <p>
                            Start Date:{' '}
                            <b>{new Date(subscription.started_at * 1000).toLocaleDateString()}</b>
                          </p>
                          <p>
                            End Date:{' '}
                            <b>
                              {new Date(subscription.current_term_end * 1000).toLocaleDateString()}
                            </b>
                          </p>
                          <Button
                            onClick={() =>
                              setExpandedSubscriptions(prev => [...prev, subscription.id])
                            }
                            type='secondary'>
                            View Metadata
                          </Button>
                          <Drawer
                            title='Metadata'
                            open={expandedSubscriptions.find(i => i === subscription.id)}
                            onClose={() =>
                              setExpandedSubscriptions(prev =>
                                prev.filter(i => i !== subscription.id)
                              )
                            }>
                            <pre>{JSON.stringify(subscription, null, 2)}</pre>
                          </Drawer>
                        </div>
                      ))}
                    </Drawer>
                  </section>

                  <section>
                    <div className='section-header'>
                      <h2>Invoices</h2>
                    </div>
                    {invoices?.length ? (
                      <Button onClick={() => setShowInvoices(true)} type='link'>
                        View Details
                      </Button>
                    ) : (
                      <Empty description='No invoices.' />
                    )}
                    <Drawer
                      title='Invoices'
                      open={showInvoices}
                      onClose={() => setShowInvoices(false)}>
                      <pre>{JSON.stringify(invoices, null, 2)}</pre>
                    </Drawer>
                  </section>

                  <section>
                    <div className='section-header'>
                      <h2>Users</h2>
                    </div>
                    {brand.brandUsers?.length ? (
                      <table>
                        <tr>
                          <th>Email</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th></th>
                        </tr>
                        {brand.brandUsers?.map((brandUser, i) => {
                          const user = brandUser.user
                          return (
                            <tr key={i}>
                              <td>
                                <Link to={`/user/${user.id}`}>{user.email}</Link>
                              </td>
                              <td>
                                {user.firstName} {user.lastName}
                              </td>
                              <td>{user.phone}</td>
                              <td className='end'>
                                <Button
                                  className='remove-btn'
                                  type='link'
                                  danger
                                  onClick={() => setRemoveUserVisible(user.id)}>
                                  <StopOutlined />
                                </Button>
                                <Modal
                                  title='Remove from Brand'
                                  open={removeUserVisible === user.id}
                                  destroyOnClose
                                  okText='Remove'
                                  onOk={removeBrandUser}
                                  okButtonProps={{
                                    danger: true,
                                  }}
                                  confirmLoading={removeUserLoading}
                                  onCancel={() => setRemoveUserVisible(false)}>
                                  <p>
                                    Are you sure you want to remove {user.firstName} from{' '}
                                    {brand.name}?
                                  </p>
                                </Modal>
                              </td>
                            </tr>
                          )
                        })}
                      </table>
                    ) : (
                      <Empty description='No users.' />
                    )}
                  </section>

                  <section>
                    <div className='section-header'>
                      <h2>Campaigns</h2>
                    </div>
                    {brand.campaigns?.length ? (
                      <ul className='list'>
                        {brand.campaigns?.map(campaign => (
                          <li key={campaign.id}>
                            <Link to={`/campaign/${campaign.id}`}>
                              {moment(campaign.date).format('L')} - {campaign.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <Empty description='No campaigns.' />
                    )}
                  </section>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <NotFound message='Brand not found.' buttonText='Go Back' to='/brands' />
        ))}

      {status === 'loading' && (
        <div className='loading'>
          <Spin /> Loading...
        </div>
      )}
    </Wrapper>
  )
}

export default Brand
