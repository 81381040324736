import { ArrowLeftOutlined, CopyOutlined, LinkOutlined, SaveOutlined } from '@ant-design/icons'
import { Alert, Button, message, Modal, Spin, Tabs, Tooltip } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, Select } from 'formik-antd'
import React, { useContext, useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useQuery, useQueryClient } from 'react-query'
import { Link, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import CampaignAffiliate from './CampaignAffiliate'
import { CampaignCompensation } from './CampaignCompensation'
import { CampaignDeliverables } from './CampaignDeliverables'
import { CampaignGeneral } from './CampaignGeneral'
import CampaignImages from './CampaignImages'
import { CampaignMetrics } from './CampaignMetrics'
import { CampaignRequirements } from './CampaignRequirements'
import { CampaignChatTemplates } from './chat-templates/CampaignChatTemplates'
import CampaignSteps from './steps/CampaignSteps'
import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import FormItem from '../../../utility/FormItem'

const { Option } = Select

const Campaign = () => {
  const history = useHistory()
  const { id } = useParams()
  const { token } = useContext(UserContext)
  const queryCache = useQueryClient()
  const [copied, setCopied] = useState(false)
  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false)
  const [publishModalVisible, setPublishModalVisible] = useState(false)
  const [sendingNotification, setSendingNotification] = useState(false)
  const [initialValues, setInitialValues] = useState(undefined)

  const { data: campaign } = useQuery(['campaign', parseInt(id)], async () => {
    const { data } = await axios.get(`${API_URL}/campaign/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    return data
  })

  useEffect(() => {
    if (campaign) {
      setInitialValues({
        brandId: campaign.brandId,
        publishDate: campaign.publishDate,
        title: campaign.title,
        socialChannels: campaign.socialChannels || [],
        variables: campaign.variables,
        strategy: campaign.strategy,
        maxPaidAmount: campaign.maxPaidAmount,
        minPaidAmount: campaign.minPaidAmount,
        paidCampaign: campaign.maxPaidAmount || campaign.minPaidAmount ? true : false,
        paymentType:
          campaign.maxPaidAmount || campaign.minPaidAmount
            ? !campaign.minPaidAmount
              ? 'fixed'
              : 'ranged'
            : '',
        status: campaign.status,
        value: campaign.value,
        maxShopifyProducts: campaign.metaData?.maxShopifyProducts || 1,
        productDescription: campaign.productDescription,
        giveawayDescription: campaign.metaData.giveawayDescription,
        requiresShipping: campaign.requiresShipping,
        minAge: campaign.minAge,
        maxAge: campaign.maxAge,
        instagramReach: campaign.instagramReach,
        instagramEngagement: campaign.instagramEngagement,
        tiktokReach: campaign.tiktokReach,
        tiktokEngagement: campaign.tiktokEngagement,
        youtubeReach: campaign.youtubeReach,
        youtubeEngagement: campaign.youtubeEngagement,
        caption: campaign.caption,
        visuals: campaign.visuals,
        description: campaign.description,
        slug: campaign.slug,
        hashtags: campaign.hashtags || [],
        photoTags: campaign.photoTags || [],
        storyMentions: campaign.storyMentions || [],
        storyHashtags: campaign.storyHashtags || [],
        contentFormats: campaign.contentFormats || [],
        minValue: campaign.metaData?.minValue || null,
        contentFormatCounts: campaign.metaData?.contentFormatCounts || {
          // Setting default count to 1 for each selected format
          ...campaign.contentFormats?.reduce((acc, format) => {
            acc[format.toLowerCase()] = 1
            return acc
          }, {}),
        },
        holdCost: campaign.holdCost,
        genders: campaign.metaData?.genders || [],
        creatorProfession: campaign.metaData?.creatorProfession,
        fulfillmentType: campaign.metaData?.fulfillmentType,
        fulfillmentTypeOther: campaign.metaData?.fulfillmentTypeOther,
        interests: campaign.categories?.map(category => category.category.slug) || [],
        unlisted: !!campaign.password,
        password: campaign.password,
        awinLink: campaign.metaData?.awinLink,
        disablePayments:
          campaign.metaData?.paymentBeta === undefined ? false : !campaign.metaData?.paymentBeta,
        enableNegotiation: campaign.metaData?.enableNegotiation,
        onlyStories: campaign.metaData?.onlyStories,
        countries: campaign?.countries?.map(country => country?.country?.countryCode) || [],
        disableQualifications: campaign.disableQualifications,
        disableActivations: campaign.disableActivations,
        affiliateCommission: campaign.metaData?.affiliateCommission,
        disableTwilio: campaign?.metaData.disableTwilio,

        // KPIs
        collabsPerMth: campaign.metaData?.collabsPerMth,
        contentCountGoal: campaign.metaData?.contentCountGoal,
        totalReachGoal: campaign.metaData?.totalReachGoal,
        totalImpressionsGoal: campaign.metaData?.totalImpressionsGoal,
        avgEngagementGoal: campaign.metaData?.avgEngagementGoal,
        avgViewsPerMth: campaign.metaData?.avgViewsPerMth,
        totalBudget: campaign.metaData?.totalBudget,

        // ROI Tracking
        likeValue: campaign.metaData?.likeValue || 0.1,
        commentValue: campaign.metaData?.commentValue || 0.1,
        viewValue: campaign.metaData?.viewValue || 0.05,
        saveValue: campaign.metaData?.saveValue || 5.0,
        shareValue: campaign.metaData?.shareValue || 5.0,
        reachValue: campaign.metaData?.reachValue || 0.05,
        impressionValue: campaign.metaData?.impressionValue || 0.05,

        // Affiliate
        affiliateLinks: campaign.metaData?.affiliateLinks || [],
        rakutenAffiliateLinks: campaign.metaData?.rakutenAffiliateLinks || [],
        impactRadiusAffiliateLinks: campaign.metaData?.impactRadiusAffiliateLinks || [],
        cjAffiliateLinks: campaign.metaData?.cjAffiliateLinks || [],

        productListId: campaign.productListId,
        brandAffiliateLinkIds: campaign.brandAffiliateLinkIds,
      })
    }
  }, [campaign])

  const handleDuplicateCampaign = async (values, { setSubmitting, setStatus }) => {
    try {
      const res = await axios.post(`${API_URL}/campaigns/duplicate/${id}/`, {
        duplicateCampaignName: values.title,
        duplicateCampaignSlug: values.slug,
      })
      message.success('Campaign duplicated')
      setDuplicateModalVisible(false)
      history.push(`/campaign/${res.data.campaignId}`)
      setInitialValues(undefined)
      queryCache.invalidateQueries(['campaign', id])
    } catch (err) {
      setStatus(err.response.data.message)
      setSubmitting(false)
    }
  }

  const handleUpdateCampaign = async data => {
    try {
      await axios.put(`${API_URL}/campaign/${campaign.id}`, {
        ...data,
        requiresShipping: !!data.requiresShipping,
      })
      await queryCache.invalidateQueries(['campaign', id])
      message.success('Campaign updated.')
      if (data.status === 'publish' && campaign.status !== 'publish') {
        // open modal to ask admin if they want to send an email notification to the brand
        setPublishModalVisible(true)
      }
    } catch (err) {
      message.error(err?.response?.data?.err || 'An unknown error occurred.')
    }
  }

  const handleNotifyBrand = async () => {
    setSendingNotification(true)
    try {
      await axios.post(`${API_URL}/admin/campaign/${campaign.id}/notifications`)
      message.success('Brand notified.')
      setPublishModalVisible(false)
    } catch (err) {
      message.error('Error notifying brand.')
    }
    setSendingNotification(false)
  }

  return (
    <Wrapper>
      {campaign && initialValues ? (
        <Formik onSubmit={handleUpdateCampaign} initialValues={initialValues}>
          {({ values, isSubmitting, setFieldValue, setValues }) => {
            return (
              <Form className='container'>
                <div className='header'>
                  <section>
                    <Link to='/campaigns'>
                      <Button icon={<ArrowLeftOutlined />} type='link'>
                        All Campaigns
                      </Button>
                    </Link>
                    <div className='divider' />
                    <div className='brand'>
                      <Link to={`/brand/${campaign.brandId}`} className='name'>
                        <Button type='link'>{campaign.brand.name}</Button>
                      </Link>
                      <CopyToClipboard
                        text={campaign.brandId}
                        onCopy={() => {
                          setCopied(campaign.brandId)
                          setTimeout(() => setCopied(false), 1000)
                        }}>
                        <Tooltip open={copied === campaign.brandId} title='Copied'>
                          <span className='copy-id'>#{campaign.brandId}</span>
                        </Tooltip>
                      </CopyToClipboard>
                    </div>
                    <div className='divider' />
                    <Button
                      type='link'
                      icon={<CopyOutlined />}
                      onClick={() => {
                        setDuplicateModalVisible(prev => !prev)
                      }}>
                      Duplicate
                    </Button>
                    <div className='divider' />
                    <a
                      href={`https://brands.creator.co/campaigns/${campaign.id}`}
                      target='_blank'
                      rel='noopener noreferrer'>
                      <Button type='link' icon={<LinkOutlined />}>
                        Dashboard
                      </Button>
                    </a>
                    <div className='divider' />
                    <a
                      href={`https://app.creator.co/campaign/${campaign.slug}`}
                      target='_blank'
                      rel='noopener noreferrer'>
                      <Button type='link' icon={<LinkOutlined />}>
                        Brief
                      </Button>
                    </a>
                  </section>
                  <section>
                    <div className='slug-container'>
                      <Input
                        name='slug'
                        placeholder='URL slug'
                        addonBefore='https://app.creator.co/campaign/'
                      />
                    </div>
                    <Select name='status' placeholder='Status' style={{ width: '120px' }}>
                      <Option value='publish'>Active</Option>
                      <Option value='paused'>Paused</Option>
                      <Option value='pending'>Pending</Option>
                      <Option value='draft'>Draft</Option>
                      <Option value='archived'>Archived</Option>
                    </Select>
                    <Button
                      loading={isSubmitting}
                      htmlType='submit'
                      type='primary'
                      icon={<SaveOutlined />}>
                      Save
                    </Button>
                  </section>
                </div>

                <div className='title-input'>
                  <Input className='title' name='title' size='large' defaultValue={values.title} />
                </div>

                <Tabs
                  items={[
                    {
                      label: 'General',
                      key: 'general',
                      children: <CampaignGeneral values={values} campaign={campaign} />,
                    },
                    {
                      label: 'Images',
                      key: 'images',
                      children: (
                        <CampaignImages campaignId={campaign.id} brandId={campaign.brandId} />
                      ),
                    },
                    {
                      label: 'Compensation',
                      key: 'compensation',
                      children: (
                        <CampaignCompensation
                          values={values}
                          setFieldValue={setFieldValue}
                          setValues={setValues}
                          campaign={campaign}
                        />
                      ),
                    },
                    {
                      label: 'Requirements',
                      key: 'requirements',
                      children: <CampaignRequirements values={values} setValues={setValues} />,
                    },
                    {
                      label: 'Deliverables',
                      key: 'posts',
                      children: <CampaignDeliverables values={values} setValues={setValues} />,
                    },
                    {
                      label: 'Steps',
                      key: 'steps',
                      children: (
                        <CampaignSteps
                          campaignId={campaign.id}
                          brandId={campaign.brandId}
                          campaignSteps={campaign.steps}
                        />
                      ),
                    },
                    {
                      label: 'Automatic Messages',
                      key: 'chat-templates',
                      children: <CampaignChatTemplates campaignId={campaign.id} />,
                    },
                    {
                      label: 'Metrics',
                      key: 'metrics',
                      children: <CampaignMetrics campaign={campaign} />,
                    },
                    {
                      label: 'Affiliate',
                      key: 'affiliate',
                      children: (
                        <CampaignAffiliate
                          campaign={campaign}
                          values={values}
                          setValues={setValues}
                        />
                      ),
                    },
                  ]}
                />

                <Modal
                  title='Duplicate Campaign'
                  open={duplicateModalVisible}
                  width={800}
                  onCancel={() => setDuplicateModalVisible(false)}
                  footer={null}>
                  <Formik
                    onSubmit={handleDuplicateCampaign}
                    initialValues={{
                      title: `${campaign.title} (Copy)`,
                      slug: `${campaign.slug}-copy`,
                    }}>
                    {({ status, isSubmitting }) => (
                      <Form>
                        {status && (
                          <Alert
                            type='error'
                            message={status}
                            showIcon
                            style={{ marginBottom: '20px' }}
                          />
                        )}
                        <FormItem label='Campaign Title'>
                          <Input required={true} name='title' />
                        </FormItem>
                        <FormItem label='Campaign Slug'>
                          <Input required={true} name='slug' />
                        </FormItem>
                        <Button loading={isSubmitting} type='primary' htmlType='submit'>
                          Submit
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Modal>

                <Modal
                  title='Notify Brand?'
                  open={publishModalVisible}
                  onCancel={() => setPublishModalVisible(false)}
                  footer={null}>
                  <PublishModal>
                    <p>
                      Would you like to send an email notification to the brand that this campaign
                      has been published?
                    </p>
                    <div className='buttons'>
                      <Button
                        type='primary'
                        loading={sendingNotification}
                        onClick={handleNotifyBrand}>
                        Yes
                      </Button>
                      <Button
                        type='secondary'
                        onClick={() => {
                          setPublishModalVisible(false)
                        }}>
                        No
                      </Button>
                    </div>
                  </PublishModal>
                </Modal>
              </Form>
            )
          }}
        </Formik>
      ) : (
        <div className='loading'>
          <Spin /> Loading...
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #f4f4f8;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .header {
    box-shadow: 0px 10px 10px -10px #00000036;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
    padding: 12px;
    position: sticky;
    top: 10px;
    background: #f4f4f8;
    z-index: 1;
    section {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      .divider {
        background: rgba(0, 0, 0, 0.1);
        height: 20px;
        width: 1px;
      }
      .ant-btn-link {
        padding: 0;
      }
      .brand {
        white-space: nowrap;
      }
      .copy-id {
        margin-left: 8px;
        font-size: 0.8rem;
        color: #999;
        cursor: pointer;
      }
      .slug-container {
        flex: 1;
        .ant-input {
          width: 100%;
        }
      }
    }
  }

  .title-input {
    margin: 0 12px 12px 12px;
  }

  h3 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 1rem;
    font-weight: bold;
  }
  .ant-tabs {
    margin: 0 20px;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  .section-wrapper {
    max-width: 600px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    margin: 20px 0;

    .affiliate-selection {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-height: 25rem;
      overflow: auto;
      ${props => props.theme.scrollbar}
      margin-top: 4px;

      .card-inner {
        .display {
          display: flex;
          width: 100%;
          background-color: #ffffff;
          border: 1px solid transparent;
          padding: 10px;
          border-radius: 5px;
          flex: 1;
          cursor: pointer;
        }

        input {
          display: none;
        }

        input:checked + .display {
          background-color: #e5f3ff;
          border-color: #a3cdf4;
        }

        &:hover {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        }
      }
    }
    &.images-section {
      max-width: 100%;
    }

    .ant-input,
    .ant-select,
    .ant-picker {
      width: 100%;
    }
    .ant-input-number {
      width: 200px;
    }
    .social-icon {
      height: 20px;
      width: 20px;
      &.not-available {
        opacity: 0.3;
        filter: grayscale(1);
      }
    }
  }

  .form-row {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
  }

  .option-wrapper {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;
  }

  .loading {
    flex: 1;
    display: grid;
    place-content: center;
    color: ${props => props.theme.crcoGrey};
  }
`

const PublishModal = styled.div`
  .buttons {
    display: flex;
    gap: 12px;
    margin-top: 20px;
  }
`

export default Campaign
